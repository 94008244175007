import React from 'react';
import PropTypes from 'prop-types';

export function AudienceIcon({ className }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={className}
        >
            <path
                d="M10 4C7.79 4 6 5.79 6 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zM4 8c0-3.314 2.686-6 6-6s6 2.686 6 6-2.686 6-6 6-6-2.686-6-6zm12.828-4.243c.391-.39 1.024-.39 1.415 0 2.343 2.344 2.343 6.142 0 8.486-.39.39-1.024.39-1.415 0-.39-.39-.39-1.024 0-1.415 1.563-1.562 1.563-4.094 0-5.656-.39-.391-.39-1.024 0-1.415zm.702 13c.134-.535.677-.861 1.212-.727 1.328.332 2.169 1.18 2.652 2.148.468.935.606 1.98.606 2.822 0 .552-.448 1-1 1s-1-.448-1-1c0-.657-.112-1.363-.394-1.928-.267-.533-.677-.934-1.349-1.102-.535-.134-.861-.677-.727-1.212zM6.5 18C5.24 18 4 19.213 4 21c0 .552-.448 1-1 1s-1-.448-1-1c0-2.632 1.893-5 4.5-5h7c2.607 0 4.5 2.368 4.5 5 0 .552-.448 1-1 1s-1-.448-1-1c0-1.787-1.24-3-2.5-3h-7z"
                transform="translate(-64 -665) translate(56 657) translate(8 8)"
            />
        </svg>
    );
}

AudienceIcon.propTypes = {
    className: PropTypes.string
};
