import { getFeatureFlag } from '@pulselive/launchdarkly-sdk-wrapper';
import * as icons from './icons';

const ROUTES = {
    HOME: {
        type: 'route',
        path: '/',
        routes: [],
        props: {
            text: 'Home'
        }
    },
    SEGMENTS: {
        type: 'route',
        path: '/segment',
        routes: [
            {
                type: 'application',
                name: '@pulselive/segmentation-microfrontend'
            }
        ],
        props: {
            text: 'segments',
            description:
                'Sort your fans into targetable segments to be used across your FDP digital marketing',
            icon: icons.AudienceIcon,
            createBtn: {
                path: '/segment/create',
                text: 'Create New'
            }
        }
    },
    CAMPAIGNS: {
        type: 'route',
        path: '/campaigns',
        routes: [
            {
                type: 'route',
                default: true,
                routes: [
                    {
                        type: 'application',
                        name: '@pulselive/fdp-campaigns-microfrontend'
                    }
                ]
            },
            {
                type: 'route',
                path: 'campaign/:id/variant/:variantId',
                routes: [
                    {
                        type: 'application',
                        name: '@pulselive/fdp-widget-configuration-microfrontend'
                    }
                ]
            }
        ],
        props: {
            text: 'Campaigns',
            description:
                'Build the assets for your campaign to target your segments and A/B test your creative, copy, and CTAs',
            icon: icons.CampaignsIcon
        }
    },
    OBJECTIVES: {
        type: 'route',
        path: '/objectives',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fdp-objectives-microfrontend'
            }
        ],
        props: {
            text: 'Objectives',
            description:
                "Create objectives and set your key result targets, to track and measure your campaign's impact",
            icon: icons.ObjectivesIcon
        }
    },
    PUSH_NOTIFICATIONS: {
        type: 'route',
        path: '/push-notifications',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fdp-push-notifications-microfrontend'
            }
        ],
        props: {
            text: 'Push Notifications',
            description:
                'Send mobile app notifications to specific fans or segments',
            icon: icons.PushNotificationsIcon
        }
    },
    JOURNEYS: {
        type: 'route',
        path: '/journeys',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fdp-journey-orchestration-microfrontend'
            }
        ],
        props: {
            text: 'Journeys',
            description:
                'Build personalised, automated fan journeys across your digital platforms based on fan behaviour',
            icon: icons.JourneysIcon
        }
    },
    FAN_DATA: {
        type: 'route',
        path: '/fan-data',
        routes: [
            {
                type: 'application',
                name: '@pulselive/fdp-fan-data-microfrontend'
            }
        ],
        props: {
            text: 'Fan Data',
            description: 'Upload your fan data from external sources',
            icon: icons.FanDataIcon
        }
    }
};

export const getRoutes = () => {
    const routes = [
        ROUTES.HOME,
        ROUTES.SEGMENTS,
        ROUTES.CAMPAIGNS,
        ...(getFeatureFlag('push-notifications')
            ? [ROUTES.PUSH_NOTIFICATIONS]
            : []),
        ...(getFeatureFlag('journeys') ? [ROUTES.JOURNEYS] : []),
        ROUTES.OBJECTIVES,
        ROUTES.FAN_DATA
    ];

    routes[0].routes.push({
        type: 'application',
        name: '@pulselive/homepage',
        props: { applicationRoutes: homeTilesData(routes) }
    });

    return routes;
};

export const navItemsData = (customRoutes) =>
    customRoutes.map((route) => {
        return {
            path: route.path,
            text: route.props.text,
            createBtn: { ...route.props.createBtn }
        };
    });

const homeTilesData = (customRoutes) =>
    customRoutes.map((route) => {
        return {
            path: route.path,
            name: route.props.text,
            description: route.props.description,
            IconComponent: route.props.icon
        };
    });
