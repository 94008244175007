import React from 'react';
import FDPLogo from './assets/fdp-logo.svg';
import {
    constructRoutes,
    constructApplications,
    constructLayoutEngine
} from 'single-spa-layout';
import {
    registerApplication,
    start,
    getAppStatus,
    getMountedApps,
    getAppNames,
    pathToActiveWhen
} from 'single-spa';
import { pulseliveSSO, READY_EVENT } from '@pulselive/keycloak-sdk-wrapper';
import {
    initialiseLaunchDarklyClient,
    isClientReady
} from '@pulselive/launchdarkly-sdk-wrapper';
import { navItemsData, getRoutes } from './routes';
import ENV from '@pulselive/fdp-endpoint-provider';

pulseliveSSO.initiated
    ? startWhenAuthed()
    : document.addEventListener(READY_EVENT, startWhenAuthed);

/**
 * Start When Authenticated
 *
 * When the user is authenticated via the keycloak SDK, start the app.
 */
function startWhenAuthed() {
    if (pulseliveSSO.keycloak.authenticated) {
        startApp();

        if (typeof window.pendo === 'undefined') {
            return;
        }

        const { email, name } = pulseliveSSO.getUserInfo();
        window.pendo.initialize({
            visitor: {
                id: pulseliveSSO.keycloak.subject,
                full_name: name, // eslint-disable-line camelcase
                email
            },
            account: {
                id: pulseliveSSO.realm
            }
        });

        return;
    }

    // Redirects user to login SSO page
    pulseliveSSO.login();
}

/**
 * Start App
 *
 * SingleSpa bootstrapping code. Initialises browser routing and appropriate
 * apps.
 */
async function startApp() {
    initialiseLaunchDarklyClient(ENV.LAUNCHDARKLY_CLIENT_ID);
    await isClientReady();

    const applicationRoutes = getRoutes();
    const routes = constructRoutes({
        routes: [
            {
                type: 'application',
                name: '@pulselive/auth-ui',
                props: {
                    applicationRoutes: navItemsData(applicationRoutes),
                    applicationLogo: () => <img src={FDPLogo} />
                }
            },
            ...applicationRoutes
        ]
    });

    const applications = constructApplications({
        routes,
        loadApp: ({ name }) => System.import(name)
    });

    const excluded = [
        pathToActiveWhen('/campaigns/campaign/:id/variant/:id'),
        pathToActiveWhen('/segment/create'),
        pathToActiveWhen('/segment/update'),
        pathToActiveWhen('/journeys/:id/edit')
    ];

    /**
     * We can use index 0 because the `auth-ui` application is first in the
     * `constructRoutes` method call above.
     *
     * This activeWhen function is passed a location when the URL changes and
     * will test if the location fits any of the blacklist criteria. If any of
     * the blacklist items are true then activeWhen should return false.
     */
    applications[0].activeWhen = (location) => {
        const list = excluded.map((isExcluded) =>
            // eslint-disable-next-line compat/compat
            isExcluded(new URL(location.href))
        );
        return list.some((item) => item === true) ? false : true;
    };

    const layoutEngine = constructLayoutEngine({
        routes,
        applications,
        active: false
    });

    layoutEngine.activate();

    applications.forEach(registerApplication);

    start({
        urlRerouteOnly: true
    });

    if (
        'serviceWorker' in navigator &&
        process.env.NODE_ENV !== 'development'
    ) {
        navigator.serviceWorker.register('/service-worker.js');
    }

    /**
     * Export some singleSpa utility functions to the window.
     * This facilitates E2E unit tests for the app.
     */
    window.getAppStatus = getAppStatus;
    window.getAppNames = getAppNames;
    window.getMountedApps = getMountedApps;
}
