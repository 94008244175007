import React from 'react';
import PropTypes from 'prop-types';

export function CampaignsIcon({ className }) {
    return (
        <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            className={className}
        >
            <g fillRule="evenodd">
                <path d="M17.5019,2.13509 C17.8102,2.31401 18,2.64353 18,3.00000192 L18,7.00000192 C19.1848,7.00000192 20.2502,7.51635 20.9812,8.33309 C21.614,9.0401 22,9.97617 22,11.0000019 C22,12.0238 21.614,12.9599 20.9812,13.6669 C20.2502,14.4836 19.1848,15.0000019 18,15.0000019 L18,19.0000019 C18,19.3565 17.8102,19.686 17.5019,19.8649 C17.1936,20.0438 16.8134,20.0451 16.5039,19.8682 L10,16.1518 L10,21.0000019 C10,21.5523 9.55228,22.0000019 9,22.0000019 L5,22.0000019 C4.44772,22.0000019 4,21.5523 4,21.0000019 L4,16.0000019 C2.89543,16.0000019 2,15.1046 2,14.0000019 L2,8.00000192 C2,6.89543 2.89543,6.00000192 4,6.00000192 L9.73444,6.00000192 L16.5039,2.13176 C16.8134,1.9549 17.1936,1.95617 17.5019,2.13509 Z M10,14 C10.174,14 10.345,14.0454 10.4961,14.1318 L16,17.2768 L16,4.72318 L10.4961,7.86824 C10.345,7.95458 10.174,8 10,8 L4,8 L4,14 L10,14 Z M6,16 L6,20 L8,20 L8,16 L6,16 Z M18,13 L18,9 C18.5922,9 19.1233,9.25615 19.491,9.66691 C19.8083,10.0214 20,10.4871 20,11 C20,11.5129 19.8083,11.9786 19.491,12.3331 C19.1233,12.7438 18.5922,13 18,13 Z"></path>
            </g>
        </svg>
    );
}

CampaignsIcon.propTypes = {
    className: PropTypes.string
};
