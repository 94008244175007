import React from 'react';
import PropTypes from 'prop-types';

export function JourneysIcon({ className }) {
    return (
        <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.2222 4H5.77778C4.79111 4 4 4.8 4 5.77778V18.2222C4 19.2 4.79111 20 5.77778 20H18.2222C19.2 20 20 19.2 20 18.2222V5.77778C20 4.8 19.2089 4 18.2222 4ZM18.2222 18.2222H5.77778V7.55556H18.2222V18.2222ZM16.4444 12H7.55556V10.2222H16.4444V12Z"
            />
        </svg>
    );
}

JourneysIcon.propTypes = {
    className: PropTypes.string
};
