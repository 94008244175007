import React from 'react';
import PropTypes from 'prop-types';

export function FanDataIcon({ className }) {
    return (
        <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className={className}
        >
            <path
                fill="#0059FF"
                d="m12.04127,2c-4.97255,0 -9.00005,1.98889 -9.00005,4.44444l0,11.11116c0,2.4555 4.03875,4.4444 9.00005,4.4444c4.9612,0 9,-1.9889 9,-4.4444l0,-11.11116c0,-2.45555 -4.0275,-4.44444 -9,-4.44444zm6.75,15.5556c0,0.5555 -2.3963,2.2222 -6.75,2.2222c-4.3538,0 -6.75005,-1.6667 -6.75005,-2.2222l0,-2.4778c1.81125,0.8666 4.185,1.3666 6.75005,1.3666c2.565,0 4.9387,-0.5 6.75,-1.3666l0,2.4778zm0,-5.0556c-1.4625,1.0556 -4.0275,1.7222 -6.75,1.7222c-2.72255,0 -5.28755,-0.6666 -6.75005,-1.7222l0,-3.12222c1.65375,0.92222 4.06125,1.51112 6.75005,1.51112c2.6887,0 5.0962,-0.5889 6.75,-1.51112l0,3.12222zm-6.75,-3.83333c-4.3538,0 -6.75005,-1.66667 -6.75005,-2.22223c0,-0.55555 2.39625,-2.22222 6.75005,-2.22222c4.3537,0 6.75,1.66667 6.75,2.22222c0,0.55556 -2.3963,2.22223 -6.75,2.22223z"
                clipRule="evenodd"
                fillRule="evenodd"
            />
        </svg>
    );
}

FanDataIcon.propTypes = {
    className: PropTypes.string
};
